<template>
  <v-container
    class="login-container pa-0"
    fill-height
    fluid
  >
    <div class="position-absolute sign-in-bg" />

    <div
      v-if="false"
      id="g_id_onload"
      data-client_id="436721683905-30tkcmv00shl18ebefrphhm2jp417h77.apps.googleusercontent.com"
      data-login_uri="https://admin.salescloud.is"
    />
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        sm="8"
        xs="10"
        lg="4"
        md="6"
      >
        <v-img
          class="mt-5 mb-5"
          width="96px"
          src="https://admin.salescloud.is/sites/all/themes/flatkit/logo_login.png"
          style="margin: 0 auto;"
        />
        <v-form
          @submit.prevent="noop"
          class="login-form"
        >
          <v-card
            outlined
            class="pa-5"
            style="border-radius: 15px;"
          >
            <!-- Check that the SDK client is not currently loading before accessing is methods -->
            <div v-if="!$auth.loading">
              <!-- show login when not authenticated -->
              <v-btn
                v-if="!$auth.isAuthenticated"
                @click="login"
								block
								color="primary"
              >
                Log in
              </v-btn>
              <!-- show logout when authenticated -->
              <v-btn
                v-if="$auth.isAuthenticated"
                @click="logout"
								color="primary"
								block
              >
                Log out
              </v-btn>
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            email: '',
            emailErrors: [],
            emailConfirmed: false,
            password: '',
            processing: false,
            processingEmail: false
        }
    },
    methods: {
      getUser() {
          let nextContinue = this.$route.query.continue
          return this.$store.dispatch('getInitialData').then(initialData => {

            if(nextContinue !== null && nextContinue !== undefined && nextContinue !== '' && nextContinue !== '/') {
              this.$router.push({path: nextContinue})
            }
            else {
              this.$router.push({path: '/dashboard'})
            }

          })
        },
        forgotPassword() {
            this.$router.push({path: '/reset/password'})
        },
        clearEmail() {
            this.email = null
            this.emailConfirmed = false
        },
        checkEmail() {
            this.processingEmail = true
            this.emailErrors = []

            this.$store.dispatch('userExistsByEmail', this.email).then(exists => {
                if(exists) {
                    this.emailConfirmed = true
                }
                else {
                    this.emailErrors.push(this.$t('enterValidEmailOrUser'))
                }
            })
            .finally(() => {
                this.processingEmail = false
            })
        },
				// Log the user in
				login() {
					this.$auth.loginWithRedirect()
				},
				// Log the user out
				logout() {
					this.$auth.logout({
						logoutParams: {
							returnTo: window.location.origin
						}
					})
				}
    },
    watch: {
        defaultCountry(newCountry, oldCountry) {
            if(oldCountry === null && newCountry !== null) {
                this.$i18n.locale = newCountry.code.toLowerCase()
            }
        }
    },
    computed: {
        accessToken() {
          return this.$store.state.accessToken
        },
        defaultCountry() {
            return this.$store.state.defaultCountry
        }
    },
    mounted() {
        console.log(this.$route)
        this.$store.dispatch('getCountries')
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://accounts.google.com/gsi/client'
        script.async = 'async'
        script.defer = 'defer'
        document.getElementsByTagName('head')[0].appendChild(script)

        if(this.accessToken !== null && this.accessToken !== undefined) {
          this.getUser()
        }

    }
}
</script>

<style scoped>


.position-absolute.sign-in-bg {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 68%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: url('/img/login_footer.png')
}

.position-absolute {
    position: absolute;
    z-index: 0;
}

.login-form {
  z-index: 100;
}

</style>
